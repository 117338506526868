<template>

  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex justify-content-between v-theme-header">
          <button @click="$router.go(-1)" class="btn-back btn-back-dashboard"></button>

          <a  :href="generateMailToLink" class="btn-theme-menu btn-theme-menu-small mb-2 position-relative">
          <img src="/img/ui-theme/heart.png" class="mr-2" style="
              position:absolute;
              left: 5px;
              top: 6px;
           "><strong  style="
                text-align: center;
            ">Email <br> Councillors</strong>
            <img src="/img/ui-theme/heart.png" class="mr-2" style="
              position:absolute;
              right: 5px;
              top: 6px;
           ">
                
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute v-content d-flex align-items-center text-center z-1">
    <iframe :src="`https://iframe.mediadelivery.net/embed/${collectionId}/${guid}?autoplay=true&loop=true`"
      loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"

export default {
  name: "VideoView",
  props: [
    'guid',
    'collectionId'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
      toEmail: ['councillors@frasercoast.qld.gov.au'],
      bccEmail: ['herveybaycommunity@gmail.com'],
      subject: 'PLEASE APPROVE THE SHERATON HERVEY BAY',
      body: "Dear Councillors,\n\n I am a resident of the Fraser Coast writing to you to indicate my support for the Sheraton Hervey Bay project.\n\nI want the project to go ahead for the following reasons …."
    };
  },
  computed: {
    generateMailToLink() {
      const toParam = encodeURIComponent(this.toEmail);
      const bccParam = encodeURIComponent(this.bccEmail);
      const subjectParam = encodeURIComponent(this.subject);
      const bodyParam = encodeURIComponent(this.body);

      const mailtoLink = `mailto:${toParam}?&subject=${subjectParam}&body=${bodyParam}&bcc=${bccParam}`;
      return mailtoLink;
    }
  },
  created() {
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>
