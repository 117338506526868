<template>
  <AssetTypeVideo />
  <div class="container h-100 d-flex align-items-center justify-content-center">
    <div class="row mobile align-content-between g-0">
      <TypeTopNav backButton="" showLogo="true" />

      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row g-0">
          <div class="col-12">

            <!-- <a href='asset/view/video/231159/3b69a852-e473-4505-8649-cd58f53c0556' class="btn-theme-menu btn-white-menu  mb-2" style="
                    letter-spacing: 1px;
                ">Jamie Durie's Big Announcement
            </a> -->

            <a :href="generateMailToLink"
             class="btn-theme-menu mb-2"><img src="/img/ui-theme/heart.png" class="mr-2" style="
                padding-right: 10px;
            "><strong>Email Councillors My Support in</strong><strong style="
                padding: 0 5px;
            ">1 CLICK
            </strong>
            <img src="/img/ui-theme/heart.png" class="mr-2">
                
          </a>
            <!-- <a
              href="mailto:test@example.com?bcc=test1@example.com,test2@example.com&subject=text&cc=test4@example.com&body=this is body">
              <button class="btn-theme-menu mb-2 btn-support">
                <img src="/img/ui-theme/heart.png" class="mr-2">
                Show Support
              </button>
            </a> -->
          </div>
        </div>
      </div>
      <ModalComponent v-if="openModal" @updateOpenValue="updateOpenModalComponent"></ModalComponent>

      <TypeBotNav />
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'
import ModalComponent from '@/components/Modal.vue';

export default {
  name: "AssetType",
  // props: {
  //   assetType: String
  // },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    ModalComponent
  },
  data() {
    return {
      assetTypes: null,
      collection: null,
      componentName: "AssetView",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + 'Master Plan Flythrough' + '/'),
      assetOrder: null,
      openModal: false,
      assetType: 'Master Plan Flythrough',
      toEmail: ['councillors@frasercoast.qld.gov.au'],
      bccEmail: ['herveybaycommunity@gmail.com'],
      subject: 'PLEASE APPROVE THE SHERATON HERVEY BAY',
      body: "Dear Councillors,\n\n I am a resident of the Fraser Coast writing to you to indicate my support for the Sheraton Hervey Bay project.\n\nI want the project to go ahead for the following reasons …."
    };
  },
  computed: {
    generateMailToLink() {
      const toParam = encodeURIComponent(this.toEmail);
      const bccParam = encodeURIComponent(this.bccEmail);
      const subjectParam = encodeURIComponent(this.subject);
      const bodyParam = encodeURIComponent(this.body);

      const mailtoLink = `mailto:${toParam}?&subject=${subjectParam}&body=${bodyParam}&bcc=${bccParam}`;
      return mailtoLink;
    }
  },
  methods: {
    updateOpenModalComponent() {
      this.openModal = !this.openModal;
    },
  },
  created() {
    // BunnyNetService.getFolders(this.folderStructure)
    //   .then(response => {
    //     // Remap array
    //     const menuItems = response.data.map((item) => {
    //       return {
    //         Guid: item.Guid,
    //         ObjectName: unslugify(item.ObjectName),
    //         LinkName: item.ObjectName,
    //         ComponentName: this.componentName,
    //         Path: item.Path,
    //         IsDirectory: item.IsDirectory,
    //         StorageZoneName: item.StorageZoneName
    //       };
    //     });

    //     // Assign type menu array
    //     if (this.assetType === 'Lobby' || this.assetType === 'Lobby Bar' || this.assetType === 'Rooftop Lounge' || this.assetType === 'Master Plan Flythrough' || this.assetType === 'Typical Hotel Room' || this.assetType === 'Typical 2 Bed Apartment' || this.assetType === 'Economics') {
    //       this.assetOrder = this.theme.bed1MenuOrder
    //     } //else if (this.assetType === '2-bed') {
    //     //this.assetOrder = this.theme.bed2MenuOrder
    //     //}

    //     // Sort array
    //     const customMenu = sortArray(menuItems, {
    //       by: ['LinkName'],
    //       order: 'LinkName',
    //       customOrders: {
    //         LinkName: this.assetOrder
    //       }
    //     })

    //     console.log(customMenu)

    //     this.assetTypes = customMenu
    //     console.log(this.assetTypes)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })

    //   // api call get video collection details
    //   BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
    //     .then(response => {

    //       this.collection = response.data[0]
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     }
    //   )
   
  },
  mounted(){
    setTimeout(()=> {
      if(window.location.href.includes("showSupport=true")){
        document.getElementsByClassName('btn-support')[0].click()
      }
    }, 500)
    
  },
  setup() {
    // load global config
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
